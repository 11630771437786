var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"columns is-centered"},[_c('div',{staticClass:"column is-three-fifths"},[_c('DefaultForm',{attrs:{"is-saving":_vm.getPlantIsSaving,"mode":_vm.mode},on:{"submit":_vm.onSubmit,"abort":_vm.onAbort},scopedSlots:_vm._u([{key:"default",fn:function(formSettings){return [_c('div',[_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":String(_vm.$tc('modules.plant.parent_plant', 1)),"type":_vm.getPlantValidationErrors &&
                _vm.getPlantValidationErrors.parent_plant_id
                  ? 'is-danger'
                  : '',"message":_vm.getPlantValidationErrors &&
                _vm.getPlantValidationErrors.parent_plant_id
                  ? _vm.getPlantValidationErrors.parent_plant_id
                  : ''}},[_c('b-dropdown',{attrs:{"placeholder":String(
                    _vm.$t('modules.plant.placeholder.parent_plant_dropdown')
                  ),"disabled":_vm.isPlantsLoading,"scrollable":true},on:{"change":_vm.onChangeParentPlantId},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
                  var active = ref.active;
return [_c('b-button',{class:{ 'is-focused': active },attrs:{"icon-left":active ? 'arrow-up' : 'arrow-down',"aria-haspopup":"true","aria-controls":"dropdown-menu","aria-expanded":active,"type":"is-white","loading":_vm.isPlantsLoading},on:{"click":function($event){_vm.isParentPlantDropdownOpened =
                        !_vm.isParentPlantDropdownOpened}}},[_vm._v(" "+_vm._s(_vm.parentPlantDropdownTrigger)+" ")])]}}],null,true),model:{value:(_vm.parentPlantId),callback:function ($$v) {_vm.parentPlantId=$$v},expression:"parentPlantId"}},[(_vm.parentPlantId)?_c('b-dropdown-item',{attrs:{"value":null}},[_c('div',{staticClass:"has-text-centered"},[_c('span',[_vm._v(_vm._s(String().capitalize(String(_vm.$t("general.reset")))))])])]):_vm._e(),_c('hr',{staticClass:"dropdown-divider"}),_vm._l((_vm.plants),function(plant){return _c('b-dropdown-item',{key:'plant-option-' + plant.id,attrs:{"value":plant.id}},[_c('div',{staticClass:"media"},[_c('figure',{staticClass:"media-left"},[_c('div',[_c('span',[_vm._v(_vm._s(plant.id))])]),_c('p',{staticClass:"image is-48x48"},[_c('img',{attrs:{"src":"https://bulma.io/images/placeholders/96x96.png","alt":"Placeholder image"}})])]),_c('div',{staticClass:"media-content"},[_c('div',{staticClass:"content"},[_c('p',[_c('strong',[_vm._v(_vm._s(plant.plant))]),_c('br'),_c('b-tag',[_vm._v(_vm._s(plant.strain))])],1)])])])])})],2)],1),_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":String().capitalize(String(_vm.$t('modules.plant.strain'))),"type":_vm.getPlantValidationErrors && _vm.getPlantValidationErrors.strain_id
                  ? 'is-danger'
                  : '',"message":_vm.getPlantValidationErrors && _vm.getPlantValidationErrors.strain_id
                  ? _vm.getPlantValidationErrors.strain_id
                  : ''}},[_c('b-autocomplete',{attrs:{"data":_vm.strains,"loading":_vm.isStrainsLoading,"placeholder":"Select a strain","icon":['fas', _vm.STRAIN_ICON].join(' '),"field":"name","clearable":true,"open-on-focus":true,"disabled":!!_vm.parentPlantId},on:{"select":_vm.onSelectStrain,"typing":_vm.onStrainsTyping},model:{value:(_vm.strain),callback:function ($$v) {_vm.strain=$$v},expression:"strain"}})],1),_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":String().capitalize(String(_vm.$t('modules.plant.comment'))),"type":_vm.getPlantValidationErrors && _vm.getPlantValidationErrors.comment
                  ? 'is-danger'
                  : '',"message":_vm.getPlantValidationErrors && _vm.getPlantValidationErrors.comment
                  ? _vm.getPlantValidationErrors.comment
                  : ''}},[_c('b-input',{attrs:{"type":"text","icon":"","placeholder":"Comment"},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}})],1),_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":String().capitalize(String(_vm.$t('modules.plant.planted_as'))),"type":_vm.getPlantValidationErrors &&
                _vm.getPlantValidationErrors.planted_as
                  ? 'is-danger'
                  : '',"message":_vm.getPlantValidationErrors &&
                _vm.getPlantValidationErrors.planted_as
                  ? _vm.getPlantValidationErrors.planted_as
                  : ''}},[_c('b-select',{attrs:{"placeholder":"Please select","required":""},model:{value:(_vm.planted_as),callback:function ($$v) {_vm.planted_as=$$v},expression:"planted_as"}},[_c('option',{attrs:{"value":"seed"}},[_vm._v(_vm._s(_vm.$t("modules.plant.seed")))]),_c('option',{attrs:{"value":"seedling"}},[_vm._v(" "+_vm._s(_vm.$t("modules.plant.seedling"))+" ")])])],1),_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":String().capitalize(String(_vm.$t('modules.plant.planted_at'))),"type":_vm.getPlantValidationErrors &&
                _vm.getPlantValidationErrors.planted_at
                  ? 'is-danger'
                  : '',"message":_vm.getPlantValidationErrors &&
                _vm.getPlantValidationErrors.planted_at
                  ? _vm.getPlantValidationErrors.planted_at
                  : ''}},[_c('b-input',{attrs:{"type":"date","icon":"","placeholder":"Planted at"},model:{value:(_vm.planted_at),callback:function ($$v) {_vm.planted_at=$$v},expression:"planted_at"}})],1),(_vm.mode === 'update')?_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":String().capitalize(
                  String(_vm.$tc('modules.plant.designation', 1))
                ),"type":_vm.getPlantValidationErrors && _vm.getPlantValidationErrors.plant
                  ? 'is-danger'
                  : '',"message":_vm.getPlantValidationErrors && _vm.getPlantValidationErrors.plant
                  ? _vm.getPlantValidationErrors.plant
                  : ''}},[_c('b-input',{attrs:{"type":"text","icon":"","placeholder":"Plant name","required":""},model:{value:(_vm.plant),callback:function ($$v) {_vm.plant=$$v},expression:"plant"}})],1):_vm._e()],1)]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }